<template>
  <div></div>
</template>

<script>
import router from "@/router";
export default {
  name: "Page_404",
  beforeCreate() {
    router.replace("/")
  }
}
</script>

<style scoped>

</style>