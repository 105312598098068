<template>
  <div>
    <md-card class="md-small-size-100 md-elevation-15 card-wrapper">
      <md-card-header>
        <div class="md-display-1" style="text-align: center">Reset Password</div>
      </md-card-header>
      <div v-if="email_not_sent">
        <md-card-content>
          <div class="">
            <md-field :class="getValidationClass_initiator('username')">
              <label >User Name</label>
              <md-input autocomplete="given-name" v-model="reset_initiate.username"
                        :disabled="sending"/>
              <span class="md-error" v-if="!$v.reset_initiate.username.required">User Name is required</span>
            </md-field>
          </div>
        </md-card-content>
        <div class="login">
          <md-button type="submit" class="md-raised md-accent" :disabled="sending" @click="getEmail()">Get OTP
          </md-button>
        </div>
      </div>

      <div v-else>
        <md-card-content>
          <div class="">
            <md-field :class="getValidationClass_reset('email_id')">
              <label >Email Id</label>
              <md-input autocomplete="emailid" v-model="reset.email_id" :disabled="sending"/>
              <span class="md-error" v-if="!$v.reset.email_id.required">Email Id is required</span>
              <span class="md-error" v-if="!$v.reset.email_id.email">Please Enter a valid Email Id</span>
            </md-field>
          </div>

          <div class="">
            <md-field :class="getValidationClass_reset('otp')">
              <label >OTP</label>
              <md-input type=""  v-model="reset.otp" :disabled="sending"/>
              <span class="md-error" v-if="!$v.reset.otp.required">OTP is required</span>
              <span class="md-error" v-if="!$v.reset.otp.numeric">OTP consists of numbers only</span>
            </md-field>
          </div>

          <div class="">
            <md-field :class="getValidationClass_reset('password')">
              <label >New Password</label>
              <md-input type="password"  v-model="reset.password" :disabled="sending"/>
              <span class="md-error" v-if="!$v.reset.password.required">Password is required</span>
            </md-field>
          </div>
        </md-card-content>
        <div class="login">
          <md-button type="submit" class="md-raised md-accent" :disabled="sending" @click="setPassword()">Reset Password
          </md-button>
        </div>
      </div>
      <br>
      <md-progress-spinner class="md-accent" :md-stroke="3" :md-diameter="30" md-mode="indeterminate"
                           v-show="this.sending"></md-progress-spinner>
    </md-card>
    <md-snackbar :md-duration="2000" :md-active.sync="showSnackbar" md-persistent>
      <span> {{ this.errorMessage }} </span>
      <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
    </md-snackbar>

    <md-dialog-alert
        :md-active.sync="reset_success"
        md-content=" <strong>Your password has been reset!</strong>"
        md-confirm-text="Confirm" />
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'
import email from "vuelidate/lib/validators/email";
import numeric from "vuelidate/lib/validators/numeric";
import axios from "axios";
import router from "@/router";

export default {
  name: "reset",
  mixins: [validationMixin],
  mounted() {
    document.title = "Modak Enterprises"
  },
  watch:{
    reset_success: function (){
      if (this.reset_success === false)
        router.push("/");
    }
  },
  data() {
    return {
      reset_initiate: {
        username: null,
      },
      reset:{
        email_id: null,
        otp: null,
        password: null
      },
      showSnackbar: false,
      email_not_sent: true,
      sending: false,
      errorMessage: null,
      reset_success: false
    }
  },
  validations: {
    reset_initiate: {
      username: {
        required,
      }
    },
    reset:{
      email_id: {
        required,
        email
      },
      otp:{
        required,
        numeric
      },
      password: {
        required
      }
    }
  },
  methods: {
    getValidationClass_initiator(fieldName) {
      const field = this.$v.reset_initiate[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    getValidationClass_reset(fieldName) {
      const field = this.$v.reset[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    async getEmail() {
      this.$v.reset_initiate.$touch();
      if (!this.$v.reset_initiate.$invalid) {
        var data = JSON.stringify({
          "username": this.reset_initiate.username,
        })
        var config = {
          method: 'post',
          url: 'https://api.modakfab.com/reset_initiate',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        var that = this;
        axios(config)
            .then(function (response) {
              window.setTimeout(() => {
                console.log(response['data']['message']);
                that.showSnackbar = true;
                that.errorMessage = response['data']['message'];
              }, 1000)
              that.email_not_sent = false;
            })
            .catch(function (error){
              console.log(error.response['data']);
              window.setTimeout(() => {
                that.showSnackbar = true;
                that.errorMessage = error.response['data']['message'];
              }, 1000)
            });
      }
    },
    async setPassword() {
      this.$v.reset.$touch();
      if (!this.$v.reset.$invalid) {
        var data = JSON.stringify({
          "email": this.reset.email_id,
          "otp" : this.reset.otp,
          "password" : this.reset.password
        })
        var config = {
          method: 'post',
          url: 'https://api.modakfab.com/reset',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        var that = this;
        axios(config)
            .then(function (response) {
              console.log(response['data']['message'])
              that.reset_success = true;
            })
            .catch(function (error){
              console.log(error.response['data']);
              window.setTimeout(() => {
                that.showSnackbar = true;
                that.errorMessage = error.response['data']['message'];
              }, 1000)
            });
      }
    }
  }
}
</script>

<style scoped>
.card-wrapper {
  border-radius: 10px;
  min-width: 40vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .card-wrapper {
    min-width: 90vw;
  }

  .login {
    left: 35%;
  }
}
</style>