<template>
  <div>
    <div class="name">
      <md-card class="md-small-size-100 md-elevation-15 card-wrapper" style="  border-radius: 10px;">
        <md-card-header>
          <div class="md-display-1" style="text-align: center">Search Estimates by Client Name</div>
        </md-card-header>
        <md-card-content>
          <md-field>
            <label>Client Name</label>
            <md-input v-model="name" type="text"/>
          </md-field>
          <div class="fetch">
            <md-button class="md-raised md-accent " v-on:click="get_data">Fetch Past Estimates</md-button>
          </div>
        </md-card-content>
      </md-card>
    </div>

    <div class="data_view" v-if="data_fetched">
      <md-table v-model="searched" md-sort="Time" md-sort-order="dsc"  md-card md-fixed-header>
        <md-table-toolbar>
          <div class="md-toolbar-section-start">
            <h1 class="md-title">Past Estimates</h1>
          </div>

          <md-field md-clearable class="md-toolbar-section-end">
            <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable" />
          </md-field>
        </md-table-toolbar>

        <md-table-empty-state
            md-label="No users found"
            :md-description="`No user found for this '${search}' query. Try a different search term or create a new user.`">
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{item }">
          <md-table-cell md-label="Estimate ID" md-sort-by="ID" md-numeric>{{ item.ID }}</md-table-cell>
          <md-table-cell md-label="Client Name" md-sort-by="Name">{{ item.Name }}</md-table-cell>
          <md-table-cell md-label="Time" md-sort-by="Time">{{ item.Time }}</md-table-cell>
          <md-table-cell md-label="Total (₹)" md-sort-by="Total">{{ item.Total }}</md-table-cell>
        </md-table-row>
      </md-table>
    </div>
  </div>
</template>

<script>

const toLower = text => {
  return text.toString().toLowerCase()
}

const searchByName = (items, term) => {
  if (term) {
    return items.filter(item => toLower(item.Name).includes(toLower(term)))
  }
  return items
}
import axios from "axios";
import router from "@/router";

export default {
  name: "estimate_client",
  data() {
    return {
      name: null,
      data_fetched: false,
      data: [],
      searched: [],
      search: null,
    }
  },
  mounted() {
    document.title = "Modak Enterprises"
  },
  methods: {
    searchOnTable () {
      this.searched = searchByName(this.searched, this.search)
    },
    get_data() {
      this.data = []
      var data = JSON.stringify({
        "client": this.name,
      })
      var config = {
        method: 'post',
        url: 'https://api.modakfab.com/data_get_client',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('Token')
        },
        data: data
      };
      const that = this;
      axios(config)
          .then(function (response) {
            response['data'].forEach(element =>{
              that.data.push({
                "ID" : element[0],
                "Name": element[1],
                "Time": new Date(parseInt(element[2])*1000).toLocaleString("en-IN"),
                "Total": element[3]
              });
            });
            that.searched = that.data;
            that.data_fetched = true
          })
          .catch(function (error){
            console.log(error);
            if (error.response.status === 401) {
              localStorage.removeItem('Token');
              router.replace("/");
            }
          });
      window.setTimeout(() => {
        this.sending = false
      }, 10)
    }
  }
}
</script>

<style scoped>

.name {
  display: flex;
  justify-content: center;
  top: 30px;
  padding: 10px;
}

.fetch {
  display: flex;
  justify-content: center;
}

.data_view{
  min-width: 30vw;
  padding: 20px;
  border-radius: 10px;
  margin-left: 10%;
  margin-right: 10%;
}

@media screen and (max-width: 1024px) {
  .data_view{
    margin-left: -5%;
    margin-right: -10%;
  }
}

</style>