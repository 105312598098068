<template>
  <div>
    <div class="form_filler">
      <div class="toolbar">
        <md-toolbar class="md-accent toolbar-content">
          <h3 class="md-title">Modak Enterprises</h3>
        </md-toolbar>
      </div>
      <div class="get_data_from_id">
        <md-field>
          <label>Fetch Data From Quotation Id</label>
          <md-input  v-model="fetch_id" />
        </md-field>
        <md-button class="md-raised md-accent" v-on:click="get_data" > Fetch Data </md-button>
      </div>
      <div class="quotation">
        <md-card class="quotation-details  md-small-size-100 md-elevation-15 ">
          <md-card-header>
            <div class="md-title" style="text-align: center">Quotation for {{ clientName }}</div>
          </md-card-header>
          <hr style="border-style: dashed"/>
          <md-card-header>
            <div class="md-title" style="text-align: center">Client Details</div>
          </md-card-header>
          <md-card-content>
            <md-field>
              <label>Name</label>
              <md-input v-model="clientName"></md-input>
              <span class="md-helper-text"></span>
            </md-field>
            <md-field>
              <label>Contact</label>
              <md-input v-model="clientContact"></md-input>
              <span class="md-helper-text">Website or Phone Number</span>
            </md-field>
            <md-field>
              <label>Site Address</label>
              <md-input v-model="clientAddress"></md-input>
            </md-field>
            <md-field>
              <label>Subject</label>
              <md-input v-model="Subject"></md-input>
            </md-field>
            <md-switch v-model="gst_check">GST to be applied</md-switch>
            <md-switch v-model="mom">Mode of Measurement to be added</md-switch>
            <md-field>
              <label>Delivery Days</label>
              <md-input v-model="delivery_days" type="number"></md-input>
            </md-field>
          </md-card-content>
          <hr style="border-style: dashed"/>
          <md-card-header>
            <div class="md-title" style="text-align: center">Product Details</div>
          </md-card-header>
          <md-card-content>
            <div class="items" v-for="(item,index) in items" :key="index">
              <div class="cut md-accent" @click="remove(index)">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-x-square" viewBox="0 0 16 16">
                  <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                  <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
                </svg>
              </div>
              <div class="unit">
                <div class="md-layout-item">
                  <md-field>
                    <md-select v-model="item.UOM" name="units" id="units" placeholder="Unit">
                      <md-option value="SqFt">SqFt</md-option>
                      <md-option value="Sqm">Sqm</md-option>
                      <md-option value="Kgs">Kgs</md-option>
                      <md-option value="Rft">Rft</md-option>
                      <md-option value="NOS">NOS</md-option>
                      <md-option value="SET">SET</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
              <md-field>
                <label>Item Description</label>
                <md-textarea v-model="item.description" md-autogrow></md-textarea>
              </md-field>
              <div class="measurement additional">
                <md-field class="full">
                  <label>Quantity</label>
                  <md-input v-model="item.quantity" type="number"/>
                </md-field>
                <md-field class="full">
                  <label>Rate</label>
                  <md-input v-model="item.rate" type="number"/>
                </md-field>
              </div>
              <div class="cut" :md-ripple="false" @click="add()">
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                     class="bi bi-plus-square" viewBox="0 0 16 16">
                  <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"/>
                  <path
                      d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
                </svg>
              </div>
              <hr style="border-style: groove"/>
            </div>
          </md-card-content>
          <md-button class="md-raised md-accent" style="position: relative; left: 22vw" v-on:click="print()" >Submit & Print
          </md-button>
        </md-card>
      </div>
    </div>
    <div class="print_data">
      <div class="header">
        <img src="../assets/logo.png" style="max-width: 10vw; position: absolute; left: 90%; top: 0; z-index: -1"
             alt="Logo"><br><br>
        <span style="font-size: 30px; font-weight: bold">MODAK&nbsp;ENTERPRISES</span><br>
        <span>Address&nbsp;-&nbsp;180/2&nbsp;SHOP&nbsp;NO&nbsp;1&nbsp;M.I.D.C.&nbsp;AMBAD&nbsp;NASHIK&nbsp;-&nbsp;422&nbsp;010</span><br>
        <span>Phone&nbsp;-&nbsp;(F)&nbsp;0253&#8209;6511430&nbsp;9422251430,&nbsp;7350039111&nbsp;(O)&nbsp;0253&#8209;2370335</span><br>
        <span style="font-size: 25px; font-weight: bold;">Quotation</span>
      </div>
      <span style="display: flex; justify-content: flex-end;" class="md-title">{{ date }}</span>
      <span style="display: flex; justify-content: flex-end;" class="md-title">Quotation Id: {{ estimate_id }}</span>
      <div class="client_details">
        <div class="client_name"><b>To - </b> {{ clientName }}</div>
        <div class="client_contact"><b>Contact - </b> {{ clientContact }}</div>
        <div class="site"><b>Address - </b> {{ clientAddress }}</div>
      </div>
      <br>

      <div>
        <md-table v-model="items" md-card class="Print_Table">
          <md-table-toolbar>
            <span class="md-title" style="text-align: center">{{Subject}}</span>
          </md-table-toolbar>
          <md-table-toolbar>
            <span class="md-title"></span>
          </md-table-toolbar>
          <md-table-row slot="md-table-row" slot-scope="{ item }">
            <md-table-cell md-label="Description" md-sort-by="name">{{ item.description }}<br>
            </md-table-cell>
            <md-table-cell md-label="Quantity" md-sort-by="size">{{ item.quantity }}</md-table-cell>
            <md-table-cell md-label="UOM" md-sort-by="add_size">{{ item.UOM }}</md-table-cell>
            <md-table-cell md-label="Rate" md-sort-by="price">₹&nbsp;{{ item.rate }}&nbsp;/&nbsp;{{item.UOM}}</md-table-cell>
          </md-table-row>
        </md-table>
      </div>
      <br>
      <div class="tc">
        <b>TERMS AND CONDITIONS :</b><br>
        <ol>
          <li>50% advance with order confirmation.<br>
          <li>Balance payment after installation<br></li>
          <li>Rates are ex-factory.<br></li>
          <li>Extra Accessories will be charge extra.<br></li>
          <div v-if="gst_check"><li>GST 18 % as applicable.<br></li></div>
          <li>Rate valid for 10 days.<br></li>
          <li>Delivery within {{ delivery_days }} days after order confirmation.<br></li>
          <li>Minimum area will be charged 50sq.ft.<br></li>
          <li>Please you have to make arrangement of electricity on the site.<br></li>
          <li>We stand warranty for our products against any manufacturing defects for a period of 12 months.<br></li>
        </ol>
      </div>
      <br>
      <div style="font-size: 16px;">
        <b>Note:</b> The Payment terms may be flexible but after te due date, the interest of 3% P.M. will be charged
      </div>
      <div class="mom" v-if="mom">
        <b>MODE OF MEASUREMENT :</b><br>
        <ol>
          <li>If guide are fixed with in the opening, the width of shutter will be equal to width of opening.</li>
          <li>If guide are fixed outside the opening 6 / 8 or 10 inches will be added in the width of opening.</li>
          <li>Height will be measured from sill level to bottom of the bracket plus 18 inches will be added &
            in case of geared operated or motorised - 24 inches will be added.</li>
          <li>Top cover will be measured to be length provided.</li>
        </ol>
      </div>
      <br>
      <div>
        <p>Awaiting for your valuable order. <br>Thanking you</p><br>
        <p>Proprietor<br>
          Kiran Modak</p>
      </div>
    </div>
  </div>
</template>

<script>
import router from "@/router";
import axios from "axios";

export default {
  name: "quotation",
  data() {
    return {
      fetch_id: null,
      clientName: null,
      estimate_id: null,
      clientContact: null,
      clientAddress: null,
      Subject: "",
      gst_check: false,
      mom: false,
      delivery_days: 20,
      items: [
        {
          description: null,
          quantity: 1,
          UOM: "SqFt",
          rate: null
        }
      ]
    }
  },
  computed: {
    date: function () {
      const event = new Date(Date.now());
      const options = {year: "numeric", month: "numeric", day: "numeric"};
      return (event.toLocaleDateString('en-IN', options));
    }
  },
  mounted() {
    if (! localStorage.getItem('Token'))
      router.replace("/")
    document.title = "Modak Enterprises"
  },
  methods: {
    get_data(){
      var quotation_data = JSON.stringify({
        "id": this.fetch_id.replace(/ /g,'')
      })
      var config = {
        method: 'post',
        url: 'https://api.modakfab.com/quotation_get_id',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('Token')
        },
        data: quotation_data
      };
      var that = this;
      axios(config)
          .then(async function (response) {
            var temp_data = response['data'][0][3];
            console.log(temp_data)
            setTimeout(await function () {
              that.clientName = temp_data['clientName'];
              that.clientContact = temp_data['clientContact']
              that.clientGSTIN = temp_data['clientGSTIN']
              that.site = temp_data['site']
              that.subject = temp_data['subject']
              that.items = temp_data['items']
            }, 100);
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.status === 401) {
              localStorage.removeItem('Token');
              router.replace("/");
            }
          });
    },
    add() {
      this.items.push({
        description: null,
        quantity: 1,
        UOM: "SqFt",
        rate: null
      })
    },
    remove(index) {
      if (this.items.length === 1)
        return;
      this.items.splice(index, 1)
    },
    print() {
      var estimate_data = JSON.stringify({
        "clientName": this.clientName,
        "clientContact": this.clientContact,
        "site": this.clientAddress,
        "subject": this.Subject,
        "items": this.items
      })
      var config = {
        method: 'post',
        url: 'https://api.modakfab.com/quotation_post',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + localStorage.getItem('Token')
        },
        data: estimate_data
      };
      var that = this;
      axios(config)
          .then(async function (response) {
            that.estimate_id = await response.data['Estimate ID'];
            setTimeout(await function () {
            }, 100);
            document.title = that.clientName + "'s Estimate " + that.date;
            window.print();
            document.title = "Modak Enterprises"
          })
          .catch(function (error) {
            console.log(error);
            if (error.response.status === 401) {
              localStorage.removeItem('Token');
              router.replace("/");
            }
          });
    },
  },
}
</script>

<style scoped>

.get_data_from_id{
  padding: 20px;
}
.toolbar {
  padding: 10px;
}

.toolbar-content {
  border-radius: 10px;
}

.quotation-details {
  min-width: 50vw;
  margin: 0 10px 0 10px;
  border-radius: 25px;
}

.quotation-details {
  min-width: 50vw;
  margin: 0 10px 0 10px;
  border-radius: 25px;
}

.quotation {
  padding: 0;
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

.unit {
  max-width: 8vw;
  position: relative;
}

.cut {
  display: flex;
  justify-content: flex-end;
}

.additional {
  display: flex;
  align-items: baseline;
}

.full {
  flex: 1;
  margin: 5px;
}

@media screen and (max-width: 1024px) {
  .quotation-details {
    min-width: 90vw;
  }

  .unit {
    min-width: 25vw;
  }

  .additional {
    display: inline-block;
  }
}

.print_data {
  display: none;
  width: 100%;
}

.header {
  text-align: center;
}

.client_details {
  font-size: 18px;
}

.tc {
  font-size: 15px;
  border-style: dashed;
  padding: 10px;
  break-inside: avoid;
}

.mom{
  font-size: 15px;
  padding: 10px;
  break-inside: avoid;
}


@media print {
  @page {
    size: portrait;
    min-width: 100vw;
  }

  .form_filler {
    display: none;
  }

  .print_data {
    display: block;
  }
}


</style>