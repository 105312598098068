import Vue from 'vue'
import VueRouter from 'vue-router'
import estimate from '../views/estimate.vue'
import login from '../views/Login'
import quotation from '../views/quotation'
import page_404 from '../views/page_404'
import dashboard from "@/views/dashboard";
import reset from "@/views/reset";
import estimate_client from "@/views/estimate_client";
import quotation_client from "@/views/quotation_client";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: login
  },
  {
    path: '/estimate',
    name: 'Estimate',
    component: estimate
  },
  {
    path: '/quotation',
    name: 'Quotation',
    component: quotation
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: dashboard
  },
  {
    path: '/Reset',
    name: 'Reset Password',
    component: reset
  },
  {
    path: "/past_estimate_by_client",
    name: "Search Past Estimate by Client Name",
    component: estimate_client
  },
  {
    path: "/past_quotation_by_client",
    name: "Search past quotation by Client Name",
    component: quotation_client
  },
  {
    path: '/*',
    name: "404",
    component: page_404
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
