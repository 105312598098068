<template>
  <div class="wrapper">
    <h1 style="text-align: center">Server has been shutdown</h1>
    <form novalidate class="" @submit.prevent="">
      <md-card class="md-small-size-100 md-elevation-15 card-wrapper">
        <md-card-header>
          <div class="md-display-1" style="text-align: center" >Login</div>
        </md-card-header>
        <md-card-content>
          <div class="">
            <div class="">
              <md-field :class="getValidationClass('username')">
                <label for="username">User Name</label>
                <md-input name="username" id="username" autocomplete="given-name" v-model="form.username"
                          :disabled="sending"/>
                <span class="md-error" v-if="!$v.form.username.required">User Name is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <md-card-content>
          <div class="">
            <div class="">
              <md-field :class="getValidationClass('password')">
                <label for="password">Password</label>
                <md-input name="password" id="password" type="password" autocomplete="password" v-model="form.password"
                          :disabled="sending"/>
                <span class="md-error" v-if="!$v.form.password.required">Password is required</span>
              </md-field>
            </div>
          </div>
        </md-card-content>
        <div class="login">
          <md-button type="submit" class="md-raised md-accent" disabled="true" @click="validateData">Login
          </md-button>
        </div>
        <br>
        <md-progress-spinner class="md-accent" :md-stroke="3" :md-diameter="30" md-mode="indeterminate"
                             v-show="this.sending"></md-progress-spinner>
      </md-card>
      <md-snackbar :md-duration="2000" :md-active.sync="showSnackbar" md-persistent>
        <span> {{ this.errorMessage }} </span>
        <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
      </md-snackbar>
    </form>
    <router-link to="/Reset">
      <md-button class="md-raised md-accent reset" disabled="true">Reset Password</md-button>
    </router-link>
  </div>
</template>

<script>
import {validationMixin} from 'vuelidate'
import {
  required,
} from 'vuelidate/lib/validators'
import axios from "axios";
import router from "@/router";


export default {
  name: "Login",
  mixins: [validationMixin],
  mounted() {
    document.title = "Modak Enterprises"
    if (localStorage.getItem('Token'))
      router.push("/dashboard")
  },
  data() {
    return {
      form: {
        username: null,
        password: null
      },
      sending: false,
      showSnackbar: false,
      errorMessage: "",
    }
  },
  validations: {
    form: {
      username: {
        required,
      },
      password: {
        required,
      }
    }
  },
  methods: {
    getValidationClass(fieldName) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    validateData() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        var data = JSON.stringify({
          "username": this.form.username,
          "password": this.form.password
        })
        var config = {
          method: 'post',
          url: 'https://api.modakfab.com/login',
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        var that = this;
        axios(config)
            .then(function (response) {
              localStorage.setItem("Token",response['data']['Token'])
              router.push("/dashboard");
            })
            .catch(function (error){
              console.log(error.response['data']);
              window.setTimeout(() => {
                  that.showSnackbar = true;
                  that.errorMessage = error.response['data']['message'];
              }, 1000)
            });
        window.setTimeout(() => {
          this.sending = false
        }, 10)
      }
    }
  }
}
</script>

<style scoped>


.reset{
  border-radius: 5px;
  position: absolute;
  top:75%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card-wrapper {
  border-radius: 10px;
  min-width: 40vw;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 1024px) {
  .card-wrapper {
    min-width: 90vw;
  }

  .login {
    left: 35%;
  }

  .reset{
    top:90%;
  }
}

</style>