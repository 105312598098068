<template>
  <div>
    <div class="mainMenu">
      <router-link to="/estimate">
        <md-button class="menu">Create Estimate</md-button>
      </router-link>
      <router-link to="/past_estimate_by_client">
        <md-button class="menu">See Past Estimates by Client Name</md-button>
      </router-link>
      <br>
      <br>
      <router-link to="/quotation">
        <md-button class="menu">Create Quotation</md-button>
      </router-link>
      <router-link to="/past_quotation_by_client">
        <md-button class="menu">See Past Quotations by Client Name</md-button>
      </router-link>
      <br>
      <md-button class="menu" v-on:click="logout">Logout</md-button>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "dashboard",
  mounted() {
    document.title = "Modak Enterprises"
    if (!localStorage.getItem('Token'))
      router.replace("/")
    var jwt = this.parseJwt();
    if(parseInt(jwt['exp']) < parseInt(Date.now()/1000)){
      localStorage.removeItem("Token")
      router.replace("/")
    }
  },
  methods: {
    parseJwt() {
      var token = localStorage.getItem('Token');
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
      return JSON.parse(jsonPayload);
    },
    logout(){
      localStorage.removeItem("Token");
      router.replace("/")
    }
  }
}
</script>

<style scoped>
.menu {
  background: #E10032;
  color: white !important;
  border-radius: 12px;
  min-width: 300px;
  min-height: 70px;
}

.mainMenu {
  margin-top: 10%;
  margin-left: 9vw;
  margin-right: 10vw;
}
</style>